import React, { useState } from "react";
import Logo from "./logo.svg";

function App() {
  return (
    <div
      id="dangnelson"
      className="d-flex align-items-center justify-content-center pb-3"
      style={{ minHeight: "100%" }}
    >
      <div className="d-flex flex-column" style={{ maxWidth: 350 }}>
        <div className="pb-3 d-flex flex-column align-items-center text-center">
          <div
            className="profile-container position-relative w-50 mb-4 rounded-circle "
            style={{
              paddingBottom: "50%",
              userSelect: "none",
              WebkitUserSelect: "none",
            }}
          >
            <div className={"position-absolute "}>
              <img
                src={Logo}
                className="w-100 img-fluid rounded-circle"
                alt="logo"
              />
            </div>
          </div>

          <h1>Boycott Pollution</h1>
          <p>
            Your guide for how to tackle the pollution of our planet and our
            bodies through consumer power.
          </p>
        </div>
        <div className="d-flex flex-column align-items-center">
          <a
            href="https://guide.boycottpollution.com"
            role="button"
            type="button"
            className="btn btn-lg btn-light mb-3 w-75"
          >
            <i className="fa-fw fa-solid fa-book me-2"></i>Read the Guide&nbsp;
          </a>

          <a
            href="https://patreon.com/boycottpollution"
            role="button"
            type="button"
            className="btn btn-outline-dark mb-2 w-75"
          >
            <i className="fa-fw fa-brands fa-patreon me-2"></i>Subscribe on
            Patreon&nbsp;
          </a>
          {/* <a
            href="mailto:dan@boycottpollution.com"
            role="button"
            type="button"
            className="btn btn-outline-dark mb-2"
          >
            <i className="fa-fw fa-solid fa-envelope"></i> Email
          </a> */}
        </div>
      </div>
    </div>
  );
}

export default App;
